/**
 * DO NOT MANUALLY EDIT THIS FILE
 * This file is generated automatically by the build script on prebuild step.
 * Please refer to documentation for more information
 */

export default function PageMeta({ children }) {
  return children({
    'frontend/art/index': {
      metaTitle: 'Browse Art | Buy Contemporary Art Online | Rise Art',
      metaDescription:
        'Browse contemporary art from exciting artists from around the world. Explore our online art gallery with artwork for sale including paintings, prints, sculpture, photography and drawing in a range of styles from abstract expressionism to pop-art. Buy or rent art online with confidence.',
      metaKeywords:
        'contemporary art, contemporary arts, contemporary abstract art, art dealers, art for sale, art websites, buy art, buy art online, original art, original art for sale, buy original art, art original, canvas wall art, fine art, fine art for sale, buy fine art online, framed art, modern art, modern art for sale, buy modern art online, online art, online art gallery, art advisors, art advisory service, art dealer, online art dealer, curated art, art curation, interior design help, art for the office, office art',
      pageTitle: 'Contemporary art for sale. Buy art online. Online art gallery.',
    },
    'frontend/article/index': {
      metaTitle: 'Art Blog | News, Releases, Art Events | Rise Art',
      metaDescription:
        'Discover the latest and greatest art news, releases and events through our Rise Art blog. Our art blog is a great place to learn more about art. We publish great features on loads of topics, from styling with art at home to going behind the scenes with our artists in their studios.',
      metaKeywords: 'art blog, art news, art events, online art gallery',
      pageTitle: 'Art blog, latest and greatest art news and events. Online Art Gallery.',
    },
    'frontend/artist/index': {
      metaTitle: 'Discover Contemporary Artists | Buy Art Online | Rise Art',
      metaDescription:
        'Discover, own and collect original art from exciting contemporary artists from around the world. View our selection of curated artists artwork for sale. Buy art online with confidence with free art advisory and 14 days free returns.',
      metaKeywords:
        'artists, famous artists, celebrity artists, contemporary artists, emerging artists, buy art online, affordable art, original art, art, unique gifts, limited edition prints, rent art, artists, contemporary art, museums',
      pageTitle: 'Discover contemporary artists and buy art online. Online Art Gallery.',
    },
    'frontend/auth/index': {
      metaTitle: 'Rise Art - Sign in/Sign up',
      metaDescription:
        'Rise Art is an online social community where anyone can discover, share and purchase unique artwork from rising talent around the globe. Rise Art enables users to directly connect and engage with the artists and their work online, and presents an exclusive gallery of curated works for sale.',
      metaKeywords:
        'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
      pageTitle: null,
    },
    'frontend/cart/checkout': {
      metaTitle: 'Rise Art - Checkout',
      metaDescription:
        'Rise Art is an online social community where anyone can discover, share and purchase unique artwork from rising talent around the globe. Rise Art enables users to directly connect and engage with the artists and their work online, and presents an exclusive gallery of curated works for sale.',
      metaKeywords:
        'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
      pageTitle: null,
    },
    'frontend/cart/index': {
      metaTitle: 'Rise Art - Shopping Cart',
      metaDescription:
        'Rise Art is an online social community where anyone can discover, share and purchase unique artwork from rising talent around the globe. Rise Art enables users to directly connect and engage with the artists and their work online, and presents an exclusive gallery of curated works for sale.',
      metaKeywords:
        'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
      pageTitle: null,
    },
    'frontend/collection/detail': {
      metaTitle: 'Art Collection | Buy Art Online | Rise Art',
      metaDescription:
        'Discover curated art collections on Rise Art. We’re an online art gallery, that means each artwork for sale has been expertly vetted by our curators. Buy art online with confidence with free art advisory and 14 days free returns.',
      metaKeywords:
        'art collections, art themed collections, curated collections, buy art online, original art, art, online art gallery, artists, contemporary art, art advisory, art for sale',
      pageTitle: 'View art collection. Buy contemporary art online. Online art gallery.',
    },
    'frontend/collection/index': {
      metaTitle: 'Curated Art Collections | Buy Art Online | Rise Art',
      metaDescription:
        'Discover curated art collections on Rise Art. Explore our online art gallery with a wide selection of art collections expertly curated by our art specialists. Buy art online with confidence with free art advisory.',
      metaKeywords:
        'art collections, art themed collections, curated collections, buy art online, original art, art, online art gallery, artists, contemporary art, art advisory, art for sale',
      pageTitle: 'Browse art collections. Buy contemporary art online. Online art gallery.',
    },
    'frontend/index/index': {
      metaTitle: 'Buy Art Online | Contemporary Art For Sale | Rise Art',
      metaDescription:
        'Discover, own and collect contemporary art from exciting artists from around the world. Explore our online art gallery with artwork for sale that has been expertly vetted by our curators. Buy or rent art online with confidence with free art advisory.',
      metaKeywords:
        'contemporary art, contemporary arts, contemporary abstract art, art dealers, art for sale, art websites, buy art, buy art online, original art, original art for sale, buy original art, art original, canvas wall art, fine art, fine art for sale, buy fine art online, framed art, modern art, modern art for sale, buy modern art online, online art, online art gallery, art advisors, art advisory service, art dealer, online art dealer, curated art, art curation, interior design help, art for the office, office art',
      pageTitle: 'Contemporary art for sale. Buy art online. Online art gallery.',
    },
    'frontend/order/confirm': {
      metaTitle: 'Rise Art - Your Order',
      metaDescription:
        'Rise Art is an online social community where anyone can discover, share and purchase unique artwork from rising talent around the globe. Rise Art enables users to directly connect and engage with the artists and their work online, and presents an exclusive gallery of curated works for sale.',
      metaKeywords:
        'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
      pageTitle: null,
    },
    'frontend/quiz/index': {
      metaTitle: 'Art Personality Test | Discover Your Art Style | Rise Art',
      metaDescription:
        'What does the art you like say about your personality? Take our free Art Personality Test and find out what your visual preferences say about you.',
      metaKeywords:
        'personality test, free personality test, art personality test, art, art quiz, art personality quiz',
      pageTitle: 'Discover your art personality on Rise Art. Take our free personality test.',
    },
  });
}
